<script setup>
import { onMounted } from 'vue';
import { useNotificationStore } from '~/stores/account/notifications.store';
const notificationStore = useNotificationStore();
const totalUnread = ref(undefined);

const fetchUnreadNotifications = async () => {
  totalUnread.value = await notificationStore.fetchUnreadNotifications();

}
onMounted(async () => {
  await fetchUnreadNotifications();
});
</script>
<template>
  <NuxtLink :to="localePath({ name: 'account.notifications' })" class="block bg-white rounded-full relative">
    <div v-if="totalUnread" class="absolute inset-0 object-right-top -mr-6 w-2.5 h-2.5 rounded-full bg-red-500 block"></div>
    <div class="rounded-full h-9 w-9 text-center leading-9">
      <i class="text-indigo-900 fa-solid fa-bell fa-lg"></i>
    </div>
  </NuxtLink>
</template>