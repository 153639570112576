<script setup>
import MobileHomeHero from './partials/hero.vue';
import MobileHomeCategories from './partials/categories.vue';
import MobileHomeBanner from './partials/projects.vue';
import helper from '~/resources/js/libraries/helper';
import { useMetaStore } from '~/stores/meta.store'
import { useUserStore } from '~/stores/user.store'
import { useLayoutStore } from '~/stores/layout.store'
import { onMounted } from 'vue';

const metaStore = useMetaStore();
const userStore = useUserStore();
const layoutStore = useLayoutStore();
const isAuth = ref();

onMounted(async () => {
  const user = computed(() => userStore.user);
  isAuth.value = !!user.value;
})

const meta = ref({});
metaStore.getMetaTags();
meta.value = metaStore.metaGetter();
metaStore.setMeta(meta.value);

const { locale } = useI18n();
const languageFlag = ref(layoutStore.languagesGetter.find((l) => l.code == locale.value)?.flag);
const currentCurrency = helper.cookies.currency();

const appId = useRuntimeConfig().public.appId;

</script>

<template>
  <LayoutAppHead></LayoutAppHead>
  <NuxtLayout name="mobile-base">
    <template #mobile-header>
      <MobileHeader>
        <template #header-left>
          <div class="flex items-center gap-10 ">
            <img :src="`/images/${appId}/logo.webp`" alt="Vartur" class="h-5 object-fill self-center">
          </div>
        </template>
        <template #header-right>
          <mobile-currency :showBorder="false">
            <template #button-design>
              <div class="bg-white rounded-full h-9 w-9 text-center leading-10">
                <span class="text-xl font-semibold text-indigo-900">{{ currentCurrency.symbol }}</span>
              </div>
            </template>
          </mobile-currency>
          <mobile-language :showBorder="false">
            <template #button-design>
              <div class="h-9 w-9 rounded-full overflow-hidden">
                <i :class="`fi fi-${languageFlag} fa-fw h-9 w-9 scale-125 block rounded-full`"></i>
              </div>
            </template>
          </mobile-language>
          <mobile-notification-button v-if="isAuth"></mobile-notification-button>
        </template>
      </MobileHeader>
    </template>

    <mobile-home-hero></mobile-home-hero>
    <mobile-home-categories></mobile-home-categories>
    <mobile-home-banner></mobile-home-banner>
  </NuxtLayout>
</template>